<template>
  <div class="notification-panel-header">
    <div class="notification-panel-header__left">
      <div v-if="totalUnread" class="notification-panel-header__circle">{{ totalUnread }}</div>

      <div class="notification-panel-header__title">
        {{ getContent(currentLocaleData, defaultLocaleData, 'notifications') }}
      </div>
    </div>

    <div class="notification-panel-header__right">
      <div v-if="totalUnread" class="notification-panel-header__read-all" @click="onReadAll">
        {{ getContent(currentLocaleData, defaultLocaleData, 'readAll') }}
      </div>

      <atomic-icon v-if="!hideCloseIcon" class="notification-panel-header__close" id="close" @click="closePanel" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { INotification } from '~/types';

  defineProps<{
    totalUnread: number;
    hideCloseIcon?: boolean;
    currentLocaleData: Maybe<INotification>;
    defaultLocaleData: Maybe<INotification>;
  }>();

  const emits = defineEmits<{
    (event: 'closePanel'): void;
    (event: 'onReadAll'): void;
  }>();

  const { getContent } = useProjectMethods();

  const closePanel = (): void => {
    emits('closePanel');
  };

  const onReadAll = (): void => {
    emits('onReadAll');
  };
</script>

<style src="~/assets/styles/components/notification/notification-panel-header.scss" lang="scss" />
