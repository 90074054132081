export enum NotificationCode {
  COMBO_BET_ACCEPTED = 'COMBO_BET_ACCEPTED',
  BET_ACCEPTED = 'BET_ACCEPTED',
  BET_LIVE_UPDATE = 'BET_LIVE_UPDATE',
  ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED',
  WITHDRAW_PROCESSING = 'WITHDRAW_PROCESSING',
  DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS',
  BET_WON = 'BET_WON',
  DEPOSIT_ERROR = 'DEPOSIT_ERROR',
  BET_REJECTED = 'BET_REJECTED',
  BET_RESULT_AVAILABLE = 'BET_RESULT_AVAILABLE',
  WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS',
  WITHDRAW_ERROR = 'WITHDRAW_ERROR',

  // на беке еще не добавлено
  WELCOME_SPORT_BONUS = 'WELCOME_SPORT_BONUS',
}

interface NotificationCategory {
  [key: string]: {
    content?: string;
    showBtn?: boolean;
    showImg?: boolean;
  };
}

interface NotificationTypes {
  [key: string]: NotificationCategory;
}

const notificationTypes: NotificationTypes = {
  bet: {
    [NotificationCode.BET_ACCEPTED]: {
      content: 'bet.betAccepted',
      showBtn: true,
    },
    [NotificationCode.COMBO_BET_ACCEPTED]: {
      content: 'bet.comboBetAccepted',
    },
    [NotificationCode.BET_WON]: {
      content: 'bet.betWon',
      showBtn: true,
    },
    [NotificationCode.BET_LIVE_UPDATE]: {
      content: 'bet.betLiveUpdate',
      showBtn: true,
    },
    [NotificationCode.BET_REJECTED]: {
      content: 'bet.betRejected',
    },
    [NotificationCode.BET_RESULT_AVAILABLE]: {
      content: 'bet.betResultAvailable',
      showBtn: true,
    },
  },
  transaction: {
    [NotificationCode.DEPOSIT_SUCCESS]: {
      content: 'transaction.depositSuccess',
    },
    [NotificationCode.DEPOSIT_ERROR]: {
      content: 'transaction.depositError',
    },
    [NotificationCode.WITHDRAW_PROCESSING]: {
      content: 'transaction.withdrawProcessing',
    },
    [NotificationCode.WITHDRAW_SUCCESS]: {
      content: 'transaction.withdrawSuccess',
    },
    [NotificationCode.WITHDRAW_ERROR]: {
      content: 'transaction.withdrawRejected',
    },
  },
  info: {
    [NotificationCode.ACCOUNT_BLOCKED]: {
      content: 'info.accountBlocked',
      showBtn: true,
    },
    // на беке еще не добавлено
    [NotificationCode.WELCOME_SPORT_BONUS]: {
      content: 'info.welcomeSportBonus',
      showBtn: true,
      showImg: true,
    },
  },
};

export { notificationTypes };
