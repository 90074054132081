<template>
  <div class="avatar" @click="handleToggle">
    <span v-if="props.isButton" class="close-decor">
      <atomic-icon id="plus" />
    </span>

    <span class="thumb">
      <span :class="['front', { front_flipped: isUserHasAvatar && !isAvatarLoadingError }]">
        <atomic-icon id="default-avatar" />
      </span>

      <span :class="['back', { back_flipped: isUserHasAvatar && !isAvatarLoadingError }]">
        <atomic-image
          v-if="isUserHasAvatar"
          class="img"
          :src="`${gamehubCdn}/${profile?.avatar_url}`"
          @loadingError="isAvatarLoadingError = true"
        />
      </span>

      <atomic-bulb v-if="props.showBulb" />

      <span class="pencil-icon">
        <atomic-icon id="pencil" />
      </span>
    </span>

    <span v-if="props.label" class="label">
      {{ props.label }}
    </span>

    <span v-if="props.nickname" class="nickname">
      {{ props.nickname }}
    </span>

    <slot name="progress-bar" />

    <span v-if="props.amount.length" class="amount">
      <span v-for="(item, index) in props.amount" :key="index">
        {{ item }}
      </span>
    </span>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    showBulb: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    nickname: {
      type: String,
      default: '',
    },
    amount: {
      type: Array,
      default: () => [],
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(['toggle']);
  const { profile } = storeToRefs(useProfileStore());
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  const { taptic } = useHaptics();

  const isUserHasAvatar = computed(() => {
    return !!profile.value?.avatar_url?.length;
  });

  const isAvatarLoadingError = ref(false);

  const handleToggle = () => {
    emit('toggle');
    taptic('soft');
  };
</script>

<style src="~/assets/styles/components/atomic/avatar.scss" lang="scss" />
