<template>
  <div class="list-notification">
    <div class="list-notification__item" v-for="item in items" :key="item.id" :data-id="item.id" ref="itemsRef">
      <card-notification
        :item="item"
        :currentLocaleData="currentLocaleData"
        :defaultLocaleData="defaultLocaleData"
        @closePanel="closePanel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IPaginationMeta, IUserNotification } from '@skeleton/core/types';
  import type { INotification } from '~/types';

  const props = defineProps<{
    items: IUserNotification[];
    meta: IPaginationMeta;
    currentLocaleData: Maybe<INotification>;
    defaultLocaleData: Maybe<INotification>;
  }>();

  const emits = defineEmits<{
    (event: 'closePanel'): void;
    (event: 'loadMore'): void;
    (event: 'markAsRead', id: string): void;
  }>();

  const { initObserver } = useProjectMethods();

  const loadMoreObserver = ref();
  const itemsRef = ref([]);
  const lastItem = ref();
  const readObserver = ref();

  const closePanel = (): void => {
    emits('closePanel');
  };

  const observerLoadMore = (): void => {
    if (props.meta.totalPages > props.meta.page) emits('loadMore');
  };

  const unobserveLastItem = (): void => {
    if (lastItem.value) {
      loadMoreObserver.value.unobserve(lastItem.value);
      lastItem.value.removeEventListener('inview', observerLoadMore);
    }
  };

  const observeLastItem = (): void => {
    unobserveLastItem();

    lastItem.value = itemsRef.value[itemsRef.value?.length - 1];

    if (lastItem.value) {
      loadMoreObserver.value.observe(lastItem.value);
      lastItem.value.addEventListener('inview', observerLoadMore);
    }
  };

  const markAsRead = (entries: IntersectionObserverEntry[]): void => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const itemId = entry.target.getAttribute('data-id');
        const notification = props.items.find(n => n.id === itemId);

        if (notification?.state === 1) {
          emits('markAsRead', itemId);
          notification.state = 2;
          readObserver.value.unobserve(entry.target);
        }
      }
    });
  };

  const observeItemsForRead = (): void => {
    if (readObserver.value) {
      readObserver.value.disconnect();
    }

    readObserver.value = new IntersectionObserver(markAsRead, {
      root: null,
      threshold: 0.8,
    });

    itemsRef.value.forEach(item => {
      const itemId = item.getAttribute('data-id');

      const notification = props.items.find(n => n.id === itemId);

      if (notification?.state === 1) {
        readObserver.value.observe(item);
      }
    });
  };

  watch(
    () => props.items,
    async () => {
      await nextTick();
      observeLastItem();
      observeItemsForRead();
    }
  );

  onMounted(() => {
    loadMoreObserver.value = initObserver({
      settings: { root: null, rootMargin: '100% 0% 100% 0%', threshold: 0 },
    });

    observeLastItem();
    observeItemsForRead();
  });

  onBeforeUnmount(() => {
    unobserveLastItem();

    if (readObserver.value) {
      readObserver.value.disconnect();
    }
  });
</script>

<style src="~/assets/styles/components/list/notification.scss" lang="scss" />
